<template>
	<div id="settleaccount">
		<div class="top">
			<div class="title">项目回款记录<i></i></div>
			<!-- <el-button type="danger" @click="settleAccountsShow = true">申请回款</el-button> -->
		</div>

		<div class="list">
			<template v-for="item in tableData">
				<div class="item" :key="item.id">
					<!-- {{item}} -->
					<div class="info">
						<div class="name">
							{{item.projectName}}
							{{collectWayEnums[item.collectWay]}}
						</div>
						<div class="date">
							申请时间：
							{{ $moment(item.createTime).format('L') }}
							{{ $moment(item.createTime).format('LT') }}
						</div>
						<div class="status">
							<el-tag size="mini" v-if="item.status=='WAIT'">
								{{statusEnums[item.status]}}
							</el-tag>
							<el-tag size="mini" v-if="item.status=='SUCCESS'" type="success">
								{{statusEnums[item.status]}}
							</el-tag>
							<el-tag size="mini" v-if="item.status=='SUBMIT'">
								{{statusEnums[item.status]}}
							</el-tag>
							<el-tag size="mini" v-if="item.status=='FINISH'" type="info">
								{{statusEnums[item.status]}}
							</el-tag>
							<el-tag size="mini" v-if="item.status=='ERROR'" type="danger">
								{{statusEnums[item.status]}}
							</el-tag>
						</div>
					</div>
					<div class="opts">
						<el-button size="small" type="primary" @click="viewOrder(item)">查看</el-button>
					</div>
				</div>
				<el-divider :key="item.id"></el-divider>
			</template>
		</div>
		<!-- 供应商结算 -->
		<el-dialog custom-class="settleAccounts" v-if="settleAccountsShow" :visible.sync="settleAccountsShow"
			title="请选择收款方式及费用类型" width="600px" center>
			<el-form ref="form" :model="accountForm" label-position="left">
				<el-row>
					<el-col :span="12">
						<el-form-item label="收款方式:">
							<el-select size="small" v-model="accountForm.collectWay" placeholder="请选择活动区域">
								<el-option v-for="(item,i) in collectWayEnums2" :key="i" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="费用类型:">
							<el-select size="small" v-model="accountForm.feeType" placeholder="请选择活动区域">
								<el-option label="会议类" value="0"></el-option>
								<el-option label="非会议类" value="1" disabled></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>


			</el-form>
			<div class="tips">
				<h3>请仔细阅读以下说明</h3>
				<p>如有 <b>一次性收款</b> ，直接选择“一次性收款并结项”</p>
				<p>如有 <b>两次收款</b> ，第一次选择“申请首次收款”，第二次选择“申请尾款”</p>
				<p>如有 <b>分三次及三次以上收款</b> ，第一次选择“申请首次收款”，中间付款选择“申请阶段收款”，最后一笔选择“申请尾款”</p>
				<p><b>据实结算</b> 的收款方式仅支持费用类型是会议类，框架服务模式，按场次收款的项目，可多次申请</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="supplierSettlement">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getOrdersByProjectId,
		getProjectCollectWay
	} from '@/request/api.js';
	export default {
		name: 'settleaccount',
		data() {
			return {
				projectId: '',
				current: 1,
				size: 10,
				total: 0,
				tableData: [],
				collectWayEnums: {
					"0": "一次性收款并结项",
					"1": "首次收款",
					"2": "阶段收款",
					"3": "尾款",
					"4": "据实结算",
				},
				collectWayEnums2: [],
				statusEnums: {
					'WAIT': '已保存待提交',
					'ERROR': '驳回待提交',
					'SUBMIT': '已提交',
					'SUCCESS': '初审成功',
					'FINISH': '流程结束',
				},

				settleAccountsShow: false,
				accountForm: {
					feeType: '0',
					collectWay: ""
				},

			}
		},
		created() {
			this.projectId = this.$route.query.pId;
			this.getOrdersByProjectIdFun();
			this.getCollectWayFun();
		},
		mounted() {},
		methods: {
			supplierSettlement() {
				this.$router.push({
					name: 'supplierSettlement',
					query: {
						pId: this.projectId,
						collectWay: this.accountForm.collectWay,
						feeType: this.accountForm.feeType
					}
				})
			},

			getCollectWayFun() {
				getProjectCollectWay({
					projectId: this.projectId
				}).then(res => {
					if (res.code == 200) {
						this.collectWayEnums2 = res.data;
						if (this.collectWayEnums2.length == 1) {
							this.accountForm.collectWay = this.collectWayEnums2[0].value;
						}
					};
				});
			},

			// 查看订单
			viewOrder(item) {
				this.$router.push({
					path: '/supplierSettlement',
					query: {
						orderId: item.id,
						pId: this.projectId
					}
				})
			},

			getOrdersByProjectIdFun() {
				let data = {
					size: this.size,
					current: this.current,
					projectId: this.projectId,
				}
				getOrdersByProjectId(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.records
						this.total = res.data.total
					}
				}).catch(err => {
					console.log(err)
				})
			},
			handleSizeChange(val) {
				this.size = val
				this.getOrdersByProjectIdFun()
			},
			handleCurrentChange(val) {
				this.current = val
				this.getOrdersByProjectIdFun()
			},
		}
	}
</script>

<style lang="scss" scoped>
	#settleaccount {
		padding: 20px;
	}

	.top {
		padding: 20px;
		margin-bottom: 20px;
		border-radius: 20px;
		box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-size: 18px;
			padding: 0 20px 0 30px;
			position: relative;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}
	}

	.list {
		padding: 20px;
		margin-bottom: 20px;
		border-radius: 20px;
		box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);

		.item {
			// margin-bottom: 20px;
			display: flex;
			align-items: center;

			.info {
				flex: 1;
				margin-right: 20px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.name {
				font-size: 17px;
				margin-bottom: 4px;
			}

			.date {
				font-size: 14px;
				margin-bottom: 4px;
			}

			.status {
				font-size: 14px;
				color: #FF6C6C;
			}
		}

		.el-divider {
			margin: 20px 0;
		}
	}

	::v-deep .settleAccounts {

		.el-dialog__body {
			padding-bottom: 10px;
		}

		.tips {
			background-color: #f6cdbb;
			padding: 20px;
			border-radius: 10px;

			h3 {
				margin-bottom: 10px;
			}

			p {
				margin-bottom: 5px;

				b {
					color: red;
				}
			}
		}
	}
</style>